import { CarshareApiService } from 'src/apis/CarshareApiService'
import { base64ToUtf8 } from 'src/libs/safeEncode'
import { Content } from 'src/types/Content'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { replaceAllContents } from '../reducers/contents'

export const setupContents = createAsyncThunk('contents/setup', async (_, { dispatch }) => {
  // Retrieve Contents
  const contents = await CarshareApiService.post<Content>('getContents', {
    content_code: 'Onboarding',
    limit: 10000,
  })

  if (contents.results) {
    const decodedContents = contents.results.map(content => ({
      ...content,
      content_data: base64ToUtf8(content.content_data),
    }))

    dispatch(replaceAllContents(decodedContents))
  }
})